.navbar-style {
    padding-left: '20px';
    padding-right: '20px';
    width: '100%';
    box-sizing: 'border-box';
  };

 .nav-item-style  {
    width: '200px';
    margin-top: '8px';
    margin-bottom: '8px';
    margin-right:'20px';
  };

  /* Allow navbar items to wrap */
.navbar-nav {
  flex-wrap: wrap;
}

/* Ensure that flex items wrap on smaller screens */
@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: column;
  }
}

/* Optional: Adjust specific spacing or margins for smaller screens */
@media (max-width: 576px) {
  .navbar-nav {
    margin-bottom: 10px;
  }
}

.flag-icon {
    width: 30px; 
    height: auto; 
    margin:5px;
  }