.color-squares-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh; /* Allow growth beyond viewport height */
}
  
  .color-square {
    width: 320px;
    min-width: 320px; /* Prevent squares from becoming too small */
    height: 250px;
    margin: 15px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: white;
    text-decoration: none;
    font-size: 26px;
    /* font-weight: bold; */
    font-family: Arial;
  }

/* Responsive for tablets */
@media (max-width: 768px) {
  .color-square {
    flex: 1 1 calc(50% - 16px); /* Two squares per row */
    max-width: calc(50% - 16px);
  }
}

/* Responsive for mobile phones */
@media (max-width: 480px) {
  .color-square {
    flex: 1 1 100%; /* Stack vertically */
    max-width: 350px;
    text-decoration: none;
  }
}
  
  .color-square h2 {
    margin: 5;
  }
  
  .color-square a {
    color: white;
  }

  .square-color-blue {
    background-color: rgba(103, 159, 230, 0.9);
    color: white
  }

  .square-color-purple {
    background-color: rgba(173, 134, 253, 0.9);
    color: white
  }

  .square-color-pink {
    background-color: rgba(255, 160, 192, 0.9);
    color: white
  }

  .square-color-green {
    background-color: rgba(47, 117, 91, 0.9);
    color: white;
  }

  .square-color-yellow {
    background-color: rgba(255, 223, 0, 0.9);
    color: white; 
  }
  /* Pink: FFA0C0
Green: 1DB954 #1db954
Blue: 679FE6
Yellow: F4D242 / FFDF00 / #f2cb08
Purple: AD86FD */
