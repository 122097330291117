
.edition-list-item-image {
  width: 135px; 
  height: 185px; 
  object-fit: cover; /* Optional: Maintain aspect ratio while covering the container */
}

.edition-page-image {
  width: 250px; 
  height: auto; 
  object-fit: cover;
  margin-bottom:20px
}

.edition-header-image {
  width: 200px; 
  height: auto; 
  object-fit: cover;
  margin:20px;
}