body {
  /* margin: 0; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
	gap: 20px;
}

.pagination .page-number {
	font-size: 16px;
	font-weight: 600;
	color: #00a8ff;
	background: #fff;
	padding: 10px 20px;
	border-radius: 10px;
	cursor: pointer;
	transition: all 0.5s ease;
}

.pagination .page-number:hover {
	color: #fff;
	background: #00a8ff;
}

.pagination .active {
	color: #fff;
	background: #00a8ff;
}

.pagination .active:hover {
	color: #00a8ff;
	background: #fff;
}

@media (max-width: 768px) {
	.pagination {
	  gap: 2px; /* Reduce spacing on smaller screens */
	}
  
	.page-number {
	  padding: 6px 8px;
	  font-size: 12px;
	}
  }