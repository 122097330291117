.App {
  text-align: left;
  overflow-y: 'hidden'
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* body, html, .App, .App * {
  font-family: 'Noto Sans Armenian', sans-serif !important;
} */

html, body, #root, .App {
  font-family: 'Noto Sans Armenian', sans-serif !important;
  height: auto; /* Allow the height to be determined by the content */
  min-height: 100%; /* Ensure it spans the viewport height if content is short */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-container {
  background: url('.././images/ALP_Flyer_020623_00.jpg');
  background-size: 'cover';
  background-position: 'center';
  max-width: 100%; 
  height: 100%;
  overflow-x: 'auto';
  overflow-y: 'hidden';
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;  
}

.content-wrap {
  flex: 1; /* Allows this section to grow and push the footer down */  
}

/* .App-container {
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box; 
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav + .container, nav + .container-fluid {
  margin-top: 20px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 1; /* Firefox */
}

#title::placeholder {
      color: #ff0000;
      opacity: 1;
  }
  .search-input::placeholder {
    color: grey; /* Change the placeholder color to grey */
  }

  .bigblue {
    color: DodgerBlue;
    padding: 40px;
    font-family: Arial;
    text-align: center;
  }

  .green-white-transparent {
    padding: 40px;
    color: white;
    background-color: hwb(166 5% 82% / 0.267);
    font-family: Arial;
  }

  .green-white {
    padding: 10px;
    color: white;
    background-color: rgb(47, 117, 91);
    /* background-color:rgb(64, 112, 81); */
    font-family: Arial;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .auth-form {
    padding: 10px;
    /* background-color: rgb(223, 188, 179); */
    background-color: rgb(237, 218, 208);
    /* background-color: rgb(239, 222, 217);  */
    font-family: Arial;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .blue-white {
    padding: 40px;
    color: white;
    background-color: blue;
    font-family: Arial;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .light-blue-white {
    padding-top: 20px;
    padding-bottom: 20px;
    min-width: 340px;
    overflow-x: 'auto';
    color: white;
    background-color: #679fe6;
    font-family: Arial;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .light-blue-page-container {
    min-width: 360px;
    overflow-x: 'auto';
    color: white;
    background-color: #679fe6;
    font-family: Arial;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .light-pink-white {
    padding: 20px;
    color: white;
    background-color: #FFA0C0;
    font-family: Arial;
    position: 'relative';
  }

  .light-purple-white {
    padding: 40px;
    color: white;
    background-color: #AD86FD;
    font-family: Arial;
  }

  .green-red {
    color: red;
    background-color: #aecab9;
    font-family: Arial;
  }

  .dark-green-white {
    padding: 20px;
    color: white;
    background-color: #2e6444;
    font-family: Arial;
    margin: '20px';
  }

  .dark-green{
    color: #4c9469;
    font-family: Arial;
  }

  .blue-white-center {
    padding: 40px;
    color: white;
    background-color: blue;
    font-family: Arial;
    text-align: center;
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Adjust the height as needed */
    border: 1px solid #ccc; /* Optional: Add a border for visualization */
  }

  .title-container {
    display: flex;
    height: auto; /* Adjust the height as needed */
    padding: 10px;
  }

  .paginated-container {
    padding-left: 20px;
  }

  .address-view-container {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin:20px;
    padding:5px;
  }

  .subview-container-blue-semitransparent {
    padding: 20px;
    color: white;
    background-color: rgba(103, 159, 230, 0.9);
    font-family: Arial;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin:20px;
  }

  .subview-container-blue {
    width: 50%;
    min-width: 340px;
    padding: 20px;
    color: white;
    background-color: #679fe6;
    font-family: Arial;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin:20px;
  }

  .light-blue-white {
    padding-top: 20px;
    padding-bottom: 20px;
    min-width: 340px;
    overflow-x: 'auto';
    color: white;
    background-color: #679fe6;
    font-family: Arial;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .contact-view-container {
    /* max-width: 50%; */
    position: relative;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin:10px;
    padding:5px;
    word-wrap: break-word; /* Allows words to break and wrap to the next line */
    word-break: break-word; /* Compatibility for older browsers */
    overflow-wrap: break-word; /* Modern CSS property for breaking long words */
    white-space: normal; /* Ensures text can wrap */
  }

  .subform-container {
    display: flex;
    min-width: 320px;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding:10px;
    color: white;
    background-color: rgb(47, 117, 91);
    font-family: Arial;
    margin:20px;
  }

  .close-button-container {
    border: 1px solid #ccc;
    border-radius: 10px;
    margin:7px;
  }

  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .custom-button{
    background-color: 'transparent';
    color: 'white';
    border: '0px';
  }

  .transparent-white-container{
    background-color: 'transparent';
    color: 'white';
    border: '0px';
  }

  .fixed-width-column {
    width: 50px!important; /* Set a fixed width with !important */    
  }

  .edit-pencil {
    width: 30px; 
    height: auto; 
    margin:5px;
  }

  .edit-pencil-small {
    width: 20px; 
    color: 'white';
    height: auto; 
    margin:5px;
  }

  .edit-pencil-container {
    position: absolute;
    top:0;
    right:0;
  }
  .edit-pencil-nested-container {
    align-items: right;
  }

  .external-logo {
    width: 100px; 
    height: auto; 
    margin:5px;
  }

  .absolute-position {
    position: absolute;
    top: 0;
    right: 0;
    /* Add other styles as needed */
  }

  .form-container {
    margin:20px;
    padding:20px;
    /* Optional: Add other styles like margin, border, etc. */
  }

  .disabled-style {
    background: #f0f0f0;
    border: none;
    box-shadow: none;
    cursor: not-allowed;
  }

  .custom-link-red {
    color: red;
  }

  .custom-link-dark-blue {
    color: rgb(9, 1, 30);
  }

  .custom-text-yellow{
    color: hsl(61, 89%, 61%);
  }

  .custom-yellow{
    color: #F4D242;
  }

  .custom-text-blue{
    color: #679fe6;
    font-family: Arial;
    font-style: italic;
  }

  .custom-link-green{
    color: rgb(17, 238, 157);
    text-decoration: none; /* No hypelink undeline */
  }

  .custom-green{
    color: rgb(17, 238, 157);
    font-style: italic;
  }
  .custom-green-bold{
    color: rgb(17, 238, 157);
    /* font-style: italic; */
    font-weight: bold;
    font-size: 20px;
  }

  .custom-blue{
    color: DodgerBlue;
    font-style: italic;
  }

  .button-link-left{
    display: flex;
    justify-content: left;
    align-items: left;
  }

  .page-image {
    width: 200px; 
    height: auto; 
    object-fit: cover; /* Optional: Maintain aspect ratio while covering the container */
    margin:20px;
  }

  .background-sticky-note {
    background-image: url('../images/sticky_note_yellow_with_red_pin.webp');
    background-size: cover; /* Ensures the image covers the entire div */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; 
    width: 320px; 
    height: 320px; 
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack items vertically */
    text-align: center;
    font-size: 20px;
    padding-left: 20px;
    padding-right: 70px;
    margin-left: 30px;
    justify-content: center; /* Center the text horizontally */
    
    color: rgb(47, 117, 91); 
  }

  div.paginated-list-container {
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    overflow-x: auto;
  }

  
  /* Pink: FFA0C0
  Green: 1DB954
  Blue: 679FE6
  Yellow: F4D242
  Purple: AD86FD */